/**
 * Authentication
 *
 */

// TODO: Use TypeScript...

import { navigate } from "gatsby"

const isBrowser = () => typeof window !== "undefined"
const isLocalhost = () => window.location.hostname === "localhost"
const isLocalAuth = () => window.location.hash.indexOf("auth") !== -1

// TODO: from .env
const ADMIN_ROLE = "BO_ADMIN"

// const LOGIN_PATH = "/login/signin";
const LOGOUT_API_PATH = "/fr/login/signout"
const USER_LOGIN_API_PATH = "/fr/login/session"

export function register() {
  // direct redirect to /login/signup
  return true
}

export function login() {
  // direct redirect to /login/signin
  return true
}

export function logout(redirectUrl) {
  if (!isBrowser()) return false

  // Clear local session
  clearSession()

  // Clear server session
  fetch(LOGOUT_API_PATH)
    .then((response) => {
      if (response.ok) {
        return response.text()
      } else {
        throw new Error("Erreur de déconnexion")
      }
    })
    .then(() => {
      const url = redirectUrl || "/"
      navigate(url)
    })
    .catch((e) => {
      console.log(e)
    })
    .finally(() => {
      navigate("/")
    })
}

export function isAuthenticated() {
  if (!isBrowser()) return false
  return !!getUser()
}

export function getUser() {
  if (!isBrowser()) return false
  return JSON.parse(window.sessionStorage.getItem("OGSHubUser"))
}

export function getUserName() {
  if (!isBrowser()) return ""
  const user = getUser()
  // TODO : configure returned data
  return user?.first_name || ""
}

export async function initUser() {
  if (!isBrowser()) return false

  // Clear local session
  clearSession()

  // Local DEV fake user / not authenticated
  if (isLocalhost()) {
    if (isLocalAuth()) return mockUser()
    return false
  }

  // const baseUrl = "https://rec.ww2.guyane-sig.fr"
  const baseUrl = ""

  // Always load data from Server on init
  try {
    const response = await fetch(baseUrl + USER_LOGIN_API_PATH)
    const session = await response.json()
    console.log(session)

    if (response.status === 200) {
      if (session.authenticated === true) {
        saveSession(session.user)
        return session.user
      }
    } else {
      clearSession()
      return false
    }
  } catch (err) {
    // TODO: add global info message
    console.log(err.response)
    clearSession()
    return false
  }
}

export function hasRoles(roles) {
  if (!isAuthenticated()) return false
  const user = getUser()
  if (roles === ADMIN_ROLE && user.is_superuser === true) {
    return true
  }
  return false
}
export function isAdmin() {
  return hasRoles(ADMIN_ROLE)
}

function saveSession(user) {
  sessionStorage.setItem("OGSHubUser", JSON.stringify(user))
  const dom = document.getElementById("___gatsby") || document.documentElement
  dom.toggleAttribute("data-ogs-authenticated", !!user)
  dom.toggleAttribute("data-ogs-isadmin", isAdmin())
}

function clearSession() {
  sessionStorage.removeItem("OGSHubUser")
  const dom = document.getElementById("___gatsby") || document.documentElement
  dom.removeAttribute("data-ogs-authenticated")
  dom.removeAttribute("data-ogs-isadmin")
}

// Dev mode
function mockUser() {
  const response_data = {
    detail: "admin session is active",
    user: {
      id: 1,
      additional_id: null,
      username: "devadmin",
      first_name: "DEV Administrator",
      last_name: "admin",
      email: "no-reply@neogeo.fr",
      is_active: true,
      status: 2,
      phone_number: null,
      usergroup_roles: [],
      is_superuser: true,
      // is_superuser: false,
      comments: "",
      date_joined: "2022-05-30T10:56:03+02:00",
      last_login: "2022-06-30T09:55:07.201839+02:00",
    },
    authenticated: true,
  }

  saveSession(response_data.user)

  return response_data.user
}
